import React from 'react'
import Logo from '../Images/Logo.png'

function NotFound() {
return (
    <div className='h-screen flex flex-col justify-center items-center'>
        <img src={Logo} alt='Logo' className='w-1/5'/>
        <h1 className='text-5xl font-comfortaa text-cyan-400'>Page Not Found</h1>
        <button onClick={() => window.location.href = '/'} className='mt-5 p-5 bg-cyan-400 text-white font-comfortaa rounded-xl'>Return to Home</button>
    </div>
)
}

export default NotFound